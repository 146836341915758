<template>
  <div class="card-block">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.card-block {
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 16px;
  background-color: $white;
}
</style>
